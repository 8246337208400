import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import Rellax from 'rellax'

import { MobileScreensSection, MobileScreensWrapper, Title, ImagesContainer, ImageBox } from './styles'
import Container from '../../UI/Container'

import { useMobileAppsImages } from '../../../graphql/useMobileAppsImagesHook'

const MobileScreens = ({ title }) => {
  const handleFadeInAnimation = () => {
    const MobileBoxes = document.querySelectorAll('.mobile-box')
    const entryOptions = {
      // threshold: 0.6,
      rootMargin: '0px 0px -40% 0px'
    }

    const featureObserver = new IntersectionObserver((entries, featureObserver) => {
      console.log(featureObserver)
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          return
        }

        if (entry.intersectionRatio > 0) {
          entry.target.style.animation = `fadeInFromBottom 1s ${entry.target.dataset.delay} forwards ease-in-out`
          featureObserver.unobserve(entry.target)
        }
      })
    }, entryOptions)

    MobileBoxes.forEach((box) => {
      featureObserver.observe(box)
    })
  }

  useEffect(() => {
    const rellax = new Rellax('.mobile-box', {
      center: true
    })
  }, [])

  const { MobileScreenOne, MobileScreenTwo, MobileScreenThree, MobileScreenFour } = useMobileAppsImages()
  return (
    <MobileScreensSection>
      <Container>
        <MobileScreensWrapper>
          <Title>{title}</Title>
          <ImagesContainer>
            <ImageBox
              marginTop="5"
              className="mobile-box fadeInFromTop"
              // data-delay=".2s"
              data-rellax-min-y="-10"
              data-rellax-max-y="100"
              data-rellax-vertical-speed="-4"
              data-rellax-vertical-scroll-axis="y"
            >
              <Img fluid={MobileScreenOne.childImageSharp.fluid} alt="" />
            </ImageBox>
            <ImageBox
              marginTop="14"
              className="mobile-box fadeInFromBottom"
              // data-delay=".4s"
              data-rellax-min-y="10"
              data-rellax-max-y="100"
              data-rellax-vertical-speed="4"
              data-rellax-vertical-scroll-axis="y"
            >
              <Img fluid={MobileScreenTwo.childImageSharp.fluid} alt="" />
            </ImageBox>
            <ImageBox
              marginTop="2"
              className="mobile-box fadeInFromTop"
              // data-delay=".6s"
              data-rellax-min-y="-10"
              data-rellax-max-y="100"
              data-rellax-vertical-speed="-4"
              data-rellax-vertical-scroll-axis="y"
            >
              <Img fluid={MobileScreenThree.childImageSharp.fluid} alt="" />
            </ImageBox>
            <ImageBox
              marginTop="10"
              className="mobile-box fadeInFromBottom"
              // data-delay=".8s"
              data-rellax-min-y="10"
              data-rellax-max-y="100"
              data-rellax-vertical-speed="4"
              data-rellax-vertical-scroll-axis="y"
            >
              <Img fluid={MobileScreenFour.childImageSharp.fluid} alt="" />
            </ImageBox>
          </ImagesContainer>
        </MobileScreensWrapper>
      </Container>
    </MobileScreensSection>
  )
}

export default MobileScreens

MobileScreens.propTypes = {
  title: PropTypes.string
}
