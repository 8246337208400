import { useStaticQuery, graphql } from 'gatsby'

export const useMobileAppsImages = () => {
  const imagess = useStaticQuery(graphql`
    fragment MobileFluidImage on File {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    query {
      MobileAppIntro: file(relativePath: { eq: "images/mobile-apps.png" }) {
        ...MobileFluidImage
      }
      MobileAppScanner: file(relativePath: { eq: "images/mobile-apps-scanner.jpg" }) {
        ...MobileFluidImage
      }
      MobileAppNotification: file(relativePath: { eq: "images/mobile-apps-notification.jpg" }) {
        ...MobileFluidImage
      }
      MobileAppMockup: file(relativePath: { eq: "images/mobile-features-footer.jpg" }) {
        ...MobileFluidImage
      }
      MobileScreenOne: file(relativePath: { eq: "images/mobile-phone-1.jpg" }) {
        ...MobileFluidImage
      }
      MobileScreenTwo: file(relativePath: { eq: "images/mobile-phone-2.jpg" }) {
        ...MobileFluidImage
      }
      MobileScreenThree: file(relativePath: { eq: "images/mobile-phone-3.jpg" }) {
        ...MobileFluidImage
      }
      MobileScreenFour: file(relativePath: { eq: "images/mobile-phone-4.jpg" }) {
        ...MobileFluidImage
      }
    }
  `)

  return imagess
}
