import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import {
  CarouselSection,
  NextArrow,
  PrevArrow,
  CarouselTitle,
  CarouselCard,
  CarouselCardRating,
  CarouselCardContent,
  CarouselCardName,
  CarouselCardText,
  ScoreCard,
  AppStore,
  GooglePlayStore
} from './styles'

import Container from '../../UI/Container'

import AppleIcon from './Icons/apple'
import GooglePlayStoreIcon from './Icons/google'
import RatingStar from './rating'

const MobileCarousel = ({ data }) => {
  const {
    app_store_title,
    app_store_score: { ios, android },
    reviews
  } = data
  const CustomNextArrow = (props) => {
    const { onClick } = props
    return <NextArrow onClick={onClick} aria-label="Next" />
  }

  const CustomPrevArrow = (props) => {
    const { onClick } = props
    return <PrevArrow onClick={onClick} aria-label="Previous" />
  }

  const settings = {
    variableWidth: true,
    dots: false,
    arrows: true,
    autoplay: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 795,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true
        }
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true
        }
      }
    ]
  }
  return (
    <CarouselSection>
      <Container>
        <CarouselTitle>{app_store_title}</CarouselTitle>
        <Slider {...settings}>
          <div>
            <ScoreCard>
              <AppStore>
                <span>{ios}</span>
                <span>App Store</span>
                <AppleIcon />
              </AppStore>
              <GooglePlayStore>
                <span>{android}</span>
                <span>Google Play</span>
                <GooglePlayStoreIcon />
              </GooglePlayStore>
            </ScoreCard>
          </div>
          {reviews.map((review, i) => {
            return (
              <div key={`${review.name}-${i}`}>
                <CarouselCard>
                  <CarouselCardRating rating={review.rate}>
                    {[...Array(5)].map((_, i) => {
                      return <RatingStar key={i} />
                    })}
                  </CarouselCardRating>
                  <CarouselCardContent>
                    <CarouselCardName>{review.name}</CarouselCardName>
                    <CarouselCardText dangerouslySetInnerHTML={{ __html: review.description }} />
                  </CarouselCardContent>
                </CarouselCard>
              </div>
            )
          })}
        </Slider>
      </Container>
    </CarouselSection>
  )
}

export default MobileCarousel

MobileCarousel.propTypes = {
  data: PropTypes.shape({
    app_store_title: PropTypes.string,
    app_store_score: PropTypes.object,
    reviews: PropTypes.arrayOf(PropTypes.object)
  }).isRequired
}
