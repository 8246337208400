import styled from 'styled-components'

export const MobileDownloadSection = styled.section`
  padding: 5rem 0;

  @media screen and (max-width: 768px) {
    padding: 2rem 0;
  }
`

export const MobileDownloadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 50%;
`

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.darkGrey};
  font-size: 1.14rem;
  line-height: 1.71rem;
  opacity: 0.8;
  text-align: center;
`

export const AppsWrapper = styled.div`
  display: flex;
  margin: 3rem 0;
  flex-basis: 20%;
  justify-content: center;

  figure {
    margin-right: 2rem;
  }

  @media screen and (max-width: 768px) {
    justify-content: space-evenly;

    figure {
      margin-right: 0;

      svg {
        width: 141px;
      }
    }
  }
`

export const ImagesWrapper = styled.div`
  position: relative;
  width: 450px;
  height: 100%;

  @media screen and (max-width: 768px) {
    width: 70%;
  }

  @media screen and (max-width: 425px) {
    width: 100%;
  }
`
