import styled from 'styled-components'

export const FaqSection = styled.section`
  padding: 5rem 0;
  height: auto;

  @media screen and (max-width: 425px) {
    padding: 2rem 0;
  }
`
export const Title = styled.h3`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 3.28rem;
  font-weight: 800;
  line-height: 3.57rem;
  text-align: center;
  margin-bottom: 7rem;

  @media screen and (max-width: 425px) {
    font-size: 2.5rem;
    line-height: 2.8rem;
    margin-bottom: 3rem;
  }
`
