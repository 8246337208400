import React from 'react'

const GoogleIcon = () => {
  return (
    <svg width="28" height="31" viewBox="0 0 28 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Google Play Store</title>
      <path
        d="M0.474074 0.888794C0.118518 1.24435 0 1.83694 0 2.54805V28.7406C0 29.4518 0.237037 30.0443 0.592593 30.3999L0.711111 30.5184L15.4074 15.8221V15.7036V15.5851L0.474074 0.888794Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M20.1483 20.7999L15.2891 15.9406V15.7036V15.5851L20.1483 10.7258L20.2668 10.8443L26.0742 14.1629C27.7335 15.111 27.7335 16.6518 26.0742 17.5999L20.1483 20.7999Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M20.2667 20.6814L15.2889 15.7036L0.474121 30.5184C1.06671 31.111 1.89634 31.111 2.96301 30.6369L20.2667 20.6814Z"
        fill="url(#paint2_linear)"
      />
      <path
        d="M20.2667 10.7259L2.96301 0.888868C1.89634 0.296275 1.06671 0.414794 0.474121 1.00739L15.2889 15.7037L20.2667 10.7259Z"
        fill="url(#paint3_linear)"
      />
      <path
        opacity="0.2"
        d="M20.1482 20.5629L2.96301 30.2814C2.01486 30.874 1.18523 30.7555 0.59264 30.2814L0.474121 30.3999L0.59264 30.5184C1.18523 30.9925 2.01486 31.111 2.96301 30.5184L20.1482 20.5629Z"
        fill="black"
      />
      <path
        opacity="0.12"
        d="M0.474073 30.2813C0.118518 29.9258 0 29.3332 0 28.6221V28.7406C0 29.4517 0.237037 30.0443 0.592592 30.3998V30.2813H0.474073Z"
        fill="black"
      />
      <path
        opacity="0.12"
        d="M26.0739 17.2444L20.1479 20.5629L20.2665 20.6814L26.0739 17.3629C26.9035 16.8888 27.2591 16.2962 27.2591 15.7036C27.2591 16.2962 26.785 16.7703 26.0739 17.2444Z"
        fill="black"
      />
      <path
        opacity="0.25"
        d="M2.96296 1.00737L26.0741 14.1629C26.7852 14.637 27.2593 15.1111 27.2593 15.7037C27.2593 15.1111 26.9037 14.5185 26.0741 14.0444L2.96296 0.888848C1.3037 -0.0593005 0 0.65181 0 2.54811V2.66663C0 0.888848 1.3037 0.0592179 2.96296 1.00737Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="13.9847"
          y1="2.32263"
          x2="-5.90554"
          y2="22.2129"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00A0FF" />
          <stop offset="0.00657445" stopColor="#00A1FF" />
          <stop offset="0.2601" stopColor="#00BEFF" />
          <stop offset="0.5122" stopColor="#00D2FF" />
          <stop offset="0.7604" stopColor="#00DFFF" />
          <stop offset="1" stopColor="#00E3FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="28.2482"
          y1="15.7054"
          x2="-0.429575"
          y2="15.7054"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE000" />
          <stop offset="0.4087" stopColor="#FFBD00" />
          <stop offset="0.7754" stopColor="#FFA500" />
          <stop offset="1" stopColor="#FF9C00" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="17.5727"
          y1="18.425"
          x2="-9.40012"
          y2="45.3978"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF3A44" />
          <stop offset="1" stopColor="#C31162" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="-3.20327"
          y1="-7.79107"
          x2="8.84129"
          y2="4.25337"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#32A071" />
          <stop offset="0.0685" stopColor="#2DA771" />
          <stop offset="0.4762" stopColor="#15CF74" />
          <stop offset="0.8009" stopColor="#06E775" />
          <stop offset="1" stopColor="#00F076" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default GoogleIcon
