import styled from 'styled-components'

export const MobileContentSection = styled.section`
  padding: 5rem 0;

  @media screen and (max-width: 768px) {
    padding: 2rem 0;
  }
`
export const MobileContentHeading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 0 7rem 0;

  @media screen and (max-width: 768px) {
    padding: 0rem 0 7rem 0;
  }
`

export const Title = styled.h3`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow', sans-serif;
  font-size: 5.71rem;
  font-weight: 800;
  line-height: 6.85rem;
  margin: 5rem 0;
  width: 75%;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 2rem;
    line-height: 2.5rem;
    width: 100%;
    margin: 2rem 0;
    text-align: left;
  }
`

export const Subtitle = styled.p`
  color: ${({ theme }) => theme.colors.darkGrey};
  font-size: 1.14rem;
  line-height: inherit;
  width: 50%;
  text-align: center;

  @media screen and (max-width: 768px) {
    width: 100%;
    text-align: left;
  }
`

export const KeyFeatureContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ direction }) => (direction ? direction : 'row')};
  align-items: center;
  padding: 5rem 0;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    padding: 2rem 0;
  }
`

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 45%;
  opacity: 0;

  @keyframes fadeInFromBottom {
    from {
      transform: translateY(0);
      opacity: 0;
    }

    to {
      transform: translateY(-50px);
      opacity: 1;
    }
  }
`

export const DescriptionTitle = styled.h4`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 2.85rem;
  font-weight: bold;
  line-height: 3.28rem;
  margin-bottom: 2rem;

  @media screen and (max-width: 768px) {
    text-align: left;
  }

  @media screen and (max-width: 425px) {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 1rem;
  }
`
export const DescriptionText = styled.div`
  color: ${({ theme }) => theme.colors.darkGrey};
  font-size: 1.14rem;
  line-height: inherit;
  opacity: 0.8;

  @media screen and (max-width: 768px) {
    text-align: left;
  }
`

export const ImageWrapper = styled.div`
  position: relative;
  width: 600px;
  height: 400px;
  opacity: 0;
  margin-bottom: 2rem;
  /* &.from-right {
    transform: translateY(50%);
  }

  &.from-right {
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    trasition-delay: 5s;
    opacity: 0;
  }

  &.from-left {
    transform: translateY(-50%);
  }

  &.from-left {
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    opacity: 0;
  }

  &.appear {
    transform: translateY(0px);
    opacity: 1;
  } */
  @keyframes fadeInFromBottom {
    from {
      transform: translateY(0);
      opacity: 0;
    }

    to {
      transform: translateY(-50px);
      opacity: 1;
    }
  }

  @media screen and (min-width: 600px) and (max-width: 768px) {
    width: 500px;
    /* height: 250px; */
  }

  @media screen and (max-width: 649px) {
    width: 400px;
    height: 300px;
  }

  @media screen and (max-width: 425px) {
    width: 90%;
    height: 250px;
  }
`

export const ImageContainer = styled.figure`
  position: ${(props) => props.position};
  background-repeat: no-repeat;
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};
  max-height: 100%;
  top: ${(props) => (props.top ? `${props.top}%` : '0%')};
  left: ${(props) => (props.left ? `${props.left}%` : '0%')};
  transform: ${({ transformY }) => `translateY(${transformY || 0}%)`};

  img {
    border-radius: 10px;
  }

  &::after {
    content: '';
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: ${({ bottom }) => `${bottom || 0}px`};
    min-height: 100%;
    width: 100%;
    z-index: 1;

    ${(props) =>
      !props.noGradient
        ? `background-image: linear-gradient(${props.deg}deg, #ffffff 3.62%, rgba(255, 255, 255, 0) 24.34%);`
        : `background-image: none;`}
  }

  @media screen and (max-width: 768px) {
    transform: translateY(0%);
    &::after {
      bottom: 0;
    }
  }
`
