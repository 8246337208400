import styled, { css } from 'styled-components'
import carouselArrow from '../../../images/next-arrow.svg'

export const CarouselSection = styled.section`
  padding: 2rem 0;
  margin: 4rem 0;
  background: linear-gradient(180deg, rgba(247, 247, 247, 0) 0%, #f7f7f7 100%);

  .slick-slider {
    margin-bottom: 2rem;
  }

  .slick-slide > div > div {
    &:focus {
      outline: none;
    }
  }

  .slick-list {
    height: 450px;
  }

  @media screen and (max-width: 795px) {
    margin-bottom: 2rem;

    .slick-dots {
      bottom: -5px;
    }

    .slick-dots li button:before {
      font-size: 12px !important;
    }

    .slick-dots li.slick-active button:before {
      color: ${({ theme }) => theme.colors.vividBlue} !important;
      opacity: 1 !important;
    }

    .slick-dots li button {
      color: ${({ theme }) => theme.colors.veryLightGray};
      &::before {
        color: ${({ theme }) => theme.colors.grey};
      }
    }
    .slick-dots li button:focus:before {
      opacity: 0.25;
    }
    .slick-dots li.slick-active button:focus:before,
    .slick-dots li button:hover:before {
      opacity: 1;
    }
  }

  @media screen and (max-width: 425px) {
    margin-bottom: 0;
    background: linear-gradient(180deg, rgba(247, 247, 247, 0) 100%, #f7f7f7 0%);
  }
`

export const NextArrow = styled.button`
  position: absolute;
  top: -125px;
  right: 0;
  border: none;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  background-image: url(${carouselArrow});
  transform: rotate(180deg);
  background-size: 8px;
  background-position: 14px;
  background-color: ${({ theme }) => theme.colors.veryDarkBlue};
  background-repeat: no-repeat;
  outline: none;
  cursor: pointer;
  opacity: 0.4;
  transition: opacity 0.2s ease-in-out;

  & :hover {
    opacity: 1;
  }

  @media screen and (max-width: 795px) {
    display: none;
  }
`

export const PrevArrow = styled.button`
  position: absolute;
  top: -125px;
  right: 70px;
  border: none;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  background-image: url(${carouselArrow});
  background-size: 8px;
  background-position: 14px;
  background-color: ${({ theme }) => theme.colors.veryDarkBlue};
  background-repeat: no-repeat;
  outline: none;
  cursor: pointer;
  opacity: 0.4;
  transition: opacity 0.2s ease-in-out;

  & :hover {
    opacity: 1;
  }

  @media screen and (max-width: 795px) {
    display: none;
  }
`

export const ScoreCard = styled.div`
  display: flex;
  width: 320px;
  height: 300px;
  margin: 1rem;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  transition: box-shadow 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  }

  @media screen and (max-width: 795px) {
    /* width: 235px; */
    /* margin: 1rem auto; */
  }

  @media screen and (max-width: 375px) {
    width: 285px;
    /* margin: 1rem auto; */
  }

  @media screen and (max-width: 320px) {
    width: 235px;
  }
`

const flexColumn = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`

export const AppStore = styled.div`
  ${flexColumn};
  background: ${({ theme }) => theme.colors.darknessBlue};
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;

  span {
    color: ${({ theme }) => theme.colors.lightestGrey};
    font-size: 0.85rem;
    font-weight: 500;
    margin: 1rem 0;

    &:first-child {
      font-size: 4.28rem;
      font-weight: 900;
      line-height: 5rem;
    }
  }
`

export const GooglePlayStore = styled.div`
  ${flexColumn};
  background: ${({ theme }) => theme.colors.veryDarkBlue};
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;

  span {
    color: ${({ theme }) => theme.colors.lightestGrey};
    font-size: 0.85rem;
    font-weight: 500;
    margin: 1rem 0;

    &:first-child {
      font-size: 4.28rem;
      font-weight: 900;
      line-height: 5rem;
    }
  }
`

export const CarouselTitle = styled.h4`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 2.85rem;
  font-weight: bold;
  line-height: 3.28rem;
  margin-bottom: 6rem;

  @media screen and (max-width: 795px) {
    text-align: center;
  }

  @media screen and (max-width: 425px) {
    font-size: 2rem;
    margin-bottom: 3rem;
  }
`

export const CarouselCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 320px;
  height: 300px;
  background: ${({ theme }) => theme.colors.pureWhite};
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin: 20px;
  transition: box-shadow 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);

    & .carousel-image {
      transform: scale(1.05);
      backface-visibility: hidden;
    }
  }

  @media screen and (max-width: 375px) {
    width: 285px;
    margin: 1rem;
  }

  @media screen and (max-width: 320px) {
    width: 235px;
  }
`
export const CarouselCardRating = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 1.42rem;
  margin-top: 3.57rem;

  svg:nth-child(-n + ${({ rating }) => rating}) {
    margin: 0 0.3rem;

    path {
      fill: ${({ theme }) => theme.colors.vividBlue};
    }
  }
`
export const CarouselCardContent = styled.div`
  padding: 0 1.42rem;
  margin: 2rem 0 2rem 0;
  overflow: hidden;
`
export const CarouselCardName = styled.h6`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 1.28rem;
  font-weight: bold;
  line-height: 1.42rem;
  margin-bottom: 1.5rem;
`
export const CarouselCardText = styled.div`
  color: ${({ theme }) => theme.colors.darkGrey};
  font-weight: normal;
  line-height: 1.71rem;

  a {
    color: ${({ theme }) => theme.colors.vividBlue};
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`
