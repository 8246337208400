import styled from 'styled-components'

export const MobileScreensSection = styled.section`
  padding: 15rem 0 8rem 0;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    padding: 9rem 0 0;
  }

  @media screen and (max-width: 425px) {
    padding: 2rem 0 0;
  }
`

export const MobileScreensWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 2.85rem;
  font-weight: bold;
  line-height: 3.28rem;
  text-align: center;
  margin-bottom: 3rem;
  width: 50%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 499px) {
    font-size: 1.7rem;
    line-height: 2.3rem;
    width: 90%;
    margin-bottom: 1rem;
  }
`

export const ImagesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 5rem 0 0;

  @media screen and (max-width: 768px) {
    margin: 1rem 0 0;
    figure {
      &:nth-child(2) {
        display: none;
      }
      &:nth-child(3) {
        display: none;
      }
    }
  }
`

export const ImageBox = styled.figure`
  width: 400px;
  height: 100%;
  margin-top: ${({ marginTop }) => `${marginTop}rem`};
  opacity: 1;

  @keyframes fadeInFromBottom {
    from {
      transform: translateY(0);
      opacity: 0;
    }

    to {
      transform: translateY(-50px);
      opacity: 1;
    }
  }
`
