import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import SEO from 'components/SEO'
import Layout from 'components/Layout'
import Header from 'components/Header'
import Footer from 'components/Footer'
import Intro from 'components/Intro'
import FAQ from 'components/Mobile-apps-screen/Mobile-FAQ'

import MobileScreens from 'components/Mobile-apps-screen/Mobile-screens'
import MobileCarousel from 'components/Mobile-apps-screen/Mobile-carousel'
import CallToAction from 'components/CTA'

import MobileContent from 'components/Mobile-apps-screen/Mobile-content'
import MobileDownload from 'components/Mobile-apps-screen/Mobile-download'
import { useMobileAppsImages } from '../graphql/useMobileAppsImagesHook'

const MainContent = styled.main`
  background-color: #ffffff;
`

const MobileApps = ({ data, pageContext, location }) => {
  const { MobileAppIntro } = useMobileAppsImages()

  const {
    mobileApps: {
      featured_media,
      title,
      excerpt,
      yoast_meta: { yoast_wpseo_title, yoast_wpseo_metadesc },
      acf: {
        intro_title_mobile_apps,
        subtitle_mobile_apps,
        content_title_mobile_apps,
        content_features_mobile_apps,
        cta_message,
        app_store_title,
        app_store_score,
        reviews,
        cta_mobile_apps,

          faq: { title: faqTitle, questions }
        
      }
    }
  } = data

  const featuredImage = featured_media !== null ? featured_media?.localFile?.childImageSharp : null
  
  const seo = {
    title: yoast_wpseo_title || title,
    description: yoast_wpseo_metadesc || excerpt,
    image: featuredImage ? featuredImage.resize : null,
    lang: pageContext.locale,
    pathname: location.pathname
  }
  
  return (
    <Layout locale={pageContext.locale}>
      <SEO
        title={seo.title}
        description={seo.description}
        pathname={seo.pathname}
        image={seo.image}
        lang={seo.lang}
        location={location}
        />
      <Header mode="light" inverted="true" locale={pageContext.locale} color={'#026C66'} location={location} />
      <Intro
        title={intro_title_mobile_apps}
        subtitle={subtitle_mobile_apps}
        introImg={MobileAppIntro.childImageSharp.fluid}
        pageName="mobile"
        yAxis={25}
        />
      <MainContent>
        <MobileScreens title={content_title_mobile_apps} />
        <MobileContent content={content_features_mobile_apps} />
        <MobileDownload message={cta_message} locale={pageContext.locale} />
        <FAQ questions={questions} title={faqTitle} />
        <MobileCarousel data={{ app_store_title, app_store_score, reviews }} />
        <CallToAction data={cta_mobile_apps} locale={pageContext.locale} location={location} />
      </MainContent>
      <Footer locale={pageContext.locale} location={location} />
    </Layout>
  )
}
 
export default MobileApps

export const query = graphql`
query($id: Int, $locale: String!) {
  mobileApps: wordpressPage(wordpress_id: { eq: $id }, lang: { eq: $locale }) {
    title
    excerpt
    yoast_meta {
      yoast_wpseo_title
      yoast_wpseo_metadesc
    }
    featured_media {
          localFile {
            childImageSharp {
              fluid(quality: 85, maxWidth: 1300) {
                ...GatsbyImageSharpFluid_withWebp
              }
              resize(width: 1200) {
                src
                height
                width
              }
            }
          }
        }
    acf {
      intro_title_mobile_apps
      subtitle_mobile_apps
        content_title_mobile_apps
        content_features_mobile_apps {
          features_title
          features_subtitle
          features {
            title
            description
          }
        }
        cta_message
        app_store_title
        app_store_score {
          ios
          android
        }
        reviews {
          rate
          name
          description
        }
        cta_mobile_apps {
          title
          description
        }
        faq {
          title
          questions {
            content
            title
          }
        }
      }
    }
  }
`
