import React from 'react'
import Img from 'gatsby-image'

import { IntroContentWrapper, IntroBox, Column, ImageContainer, Title, Subtitle } from './styles'
import Container from '../UI/Container'

const Intro = ({ introImg, title, subtitle, pageName, yAxis }) => {
  return (
    <IntroContentWrapper pageName={pageName}>
      <Container>
        <IntroBox>
          <Column>
            <Subtitle pageName={pageName}>{subtitle}</Subtitle>
            <Title>{title}</Title>
          </Column>
          <Column>
            <ImageContainer pageName={pageName} yAxis={yAxis}>
              <Img fluid={introImg} placeholderStyle={{ visibility: 'hidden' }} />
            </ImageContainer>
          </Column>
        </IntroBox>
      </Container>
    </IntroContentWrapper>
  )
}

export default Intro
