import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { handleTrackedRentlioWebappClick } from 'helper/url'
import { useMixpanel } from 'gatsby-plugin-mixpanel'

import { MobileDownloadSection, MobileDownloadWrapper, Description, Text, ImagesWrapper, AppsWrapper } from './styles'

import Container from '../../UI/Container'

import { AppStoreIcon, GooglePlayIcon } from '../../Store-icons'
import { useMobileAppsImages } from '../../../graphql/useMobileAppsImagesHook'

const MobileDownload = ({ message, locale }) => {
  const { MobileAppMockup } = useMobileAppsImages()
  const mixpanel = useMixpanel()
  return (
    <MobileDownloadSection>
      <Container>
        <MobileDownloadWrapper>
          <ImagesWrapper>
            <figure>
              <Img fluid={MobileAppMockup.childImageSharp.fluid} alt="" />
            </figure>
          </ImagesWrapper>
          <Description>
            <Text>{message}</Text>
            <AppsWrapper>
              <a
                href={
                  locale === 'hr'
                    ? 'https://apps.apple.com/hr/app/rentlio/id1122008618?l=hr'
                    : 'https://apps.apple.com/hr/app/rentlio/id1122008618'
                }
                rel="noreferrer"
                onClick={(e) =>
                  handleTrackedRentlioWebappClick(
                    e,
                    locale === 'hr'
                    ? 'https://apps.apple.com/hr/app/rentlio/id1122008618?l=hr'
                    : 'https://apps.apple.com/hr/app/rentlio/id1122008618',
                    '',
                    mixpanel,
                    'app_store_download_clicked',
                    'mobile_app',
                    true,
                    true,
                    true
                  )
                }
              >
                <figure>
                  <AppStoreIcon />
                </figure>
              </a>
              <a
                href={
                  locale === 'hr'
                    ? 'https://play.google.com/store/apps/details?id=com.rentlio.app&hl=hr'
                    : 'https://play.google.com/store/apps/details?id=com.rentlio.app&hl=en'
                }
                rel="noreferrer"
                onClick={(e) =>
                  handleTrackedRentlioWebappClick(
                    e,
                    locale === 'hr'
                    ? 'https://play.google.com/store/apps/details?id=com.rentlio.app&hl=hr'
                    : 'https://play.google.com/store/apps/details?id=com.rentlio.app&hl=en',
                    '',
                    mixpanel,
                    'google_play_download_clicked',
                    'mobile_app',
                    true,
                    true,
                    true
                  )
                }
              >
                <figure>
                  <GooglePlayIcon />
                </figure>
              </a>
            </AppsWrapper>
          </Description>
        </MobileDownloadWrapper>
      </Container>
    </MobileDownloadSection>
  )
}

export default MobileDownload

MobileDownload.propTypes = {
  message: PropTypes.string
}
