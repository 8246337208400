import React, { useEffect } from 'react'
import Img from 'gatsby-image'

import {
  MobileContentSection,
  MobileContentHeading,
  Title,
  Subtitle,
  KeyFeatureContainer,
  Description,
  DescriptionTitle,
  DescriptionText,
  ImageWrapper,
  ImageContainer
} from './styles'

import Container from '../../UI/Container'
import { useMobileAppsImages } from '../../../graphql/useMobileAppsImagesHook'

const MobileContent = ({ content }) => {
  const { features_title, features_subtitle, features } = content

  const handleFadeInAnimation = () => {
    const contentBoxes = document.querySelectorAll('.slide-in')
    const entryOptions = {
      threshold: 0.4
      //   rootMargin: '0px 0px -00px 0px'
    }

    const featureObserver = new IntersectionObserver((entries, featureObserver) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          return
        }

        if (entry.intersectionRatio > 0) {
          if (entry.intersectionRatio > 0) {
            entry.target.style.animation = `fadeInFromBottom 1s ${entry.target.dataset.delay}  forwards ease-in-out`
            featureObserver.unobserve(entry.target)
          }
        }
      })
    }, entryOptions)

    contentBoxes.forEach((box) => {
      featureObserver.observe(box)
    })
  }

  useEffect(() => {
    handleFadeInAnimation()
  }, [])

  const { MobileAppScanner, MobileAppNotification } = useMobileAppsImages()
  return (
    <MobileContentSection>
      <Container>
        <MobileContentHeading>
          <Title>{features_title}</Title>
          <Subtitle>{features_subtitle}</Subtitle>
        </MobileContentHeading>
        <KeyFeatureContainer direction="row-reverse">
          <Description className="slide-in from-right" data-delay=".2s">
            <DescriptionTitle>{features[0].title}</DescriptionTitle>
            <DescriptionText dangerouslySetInnerHTML={{ __html: features[0].description }} />
          </Description>
          <ImageWrapper className="slide-in from-left" data-delay=".1s">
            <ImageContainer position="absolute" deg="0" transformY="-25" bottom="-50">
              <Img fluid={MobileAppScanner.childImageSharp.fluid} />
            </ImageContainer>
          </ImageWrapper>
        </KeyFeatureContainer>
        <KeyFeatureContainer direction="row">
          <Description className="slide-in from-left" data-delay=".2s">
            <DescriptionTitle>{features[1].title}</DescriptionTitle>
            <DescriptionText dangerouslySetInnerHTML={{ __html: features[1].description }} />
          </Description>
          <ImageWrapper className="slide-in from-right" data-delay=".1s">
            <ImageContainer position="absolute" deg="0" transformY="-25">
              <Img fluid={MobileAppNotification.childImageSharp.fluid} />
            </ImageContainer>
          </ImageWrapper>
        </KeyFeatureContainer>
      </Container>
    </MobileContentSection>
  )
}

export default MobileContent
