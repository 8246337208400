import styled, { css } from 'styled-components'

const setRadialGradientColor = ({ pageName }) => {
  switch (pageName) {
    case 'mobile':
      return css`
        background: radial-gradient(60.92% 103.45% at 77.12% 70.8%, #afe480 29.75%, #037972 100%);
        @media screen and (max-width: 425px) {
          background: radial-gradient(200.43% 145.09% at 68.72% 129.4%, #afe480 29.75%, #037972 100%);
        }
      `
    case 'pms':
      return css`
        background: radial-gradient(56.42% 102.55% at 75.03% 63.4%, #dc4a4a 0%, #6a217e 100%);
        @media screen and (max-width: 425px) {
          background: radial-gradient(173.58% 121.81% at 56.16% 90.73%, #dc4a4a 0%, #6a217e 100%);
        }
      `
    case 'cm':
      return css`
        background: radial-gradient(69.78% 154.95% at 88.73% 92.1%, #ffe644 10.82%, #dc5a22 100%);
        @media screen and (max-width: 425px) {
          background: linear-gradient(307.58deg, #ffe644 16.85%, #dc5a22 79.73%);
        }
      `
    case 'booking':
      return css`
        background: radial-gradient(74.16% 113% at 84.93% 86.27%, #66efed 0%, #004c84 100%);
        @media screen and (max-width: 425px) {
          background: radial-gradient(127.59% 125.07% at 72.58% 105.53%, #66efed 0%, #004c84 100%);
        }
      `
  }
}

const setLinearGradientColor = ({ pageName }) => {
  switch (pageName) {
    case 'mobile':
      return css`
        background: linear-gradient(90deg, rgba(24, 168, 131, 0.7) 6.86%, rgba(110, 189, 122, 0) 90.76%);
        @media screen and (max-width: 425px) {
          background: linear-gradient(90deg, rgba(110, 189, 122, 0) 6.86%, rgba(24, 168, 131, 0.7) 90.76%);
        }
      `
    case 'booking':
      return `background: linear-gradient(90deg, #1AA5B6 6.86%, rgba(26, 165, 182, 0) 90.76%);`
    case 'pms':
      return `background: linear-gradient(90deg, #CF3966 6.86%, rgba(247, 73, 77, 0) 64.44%);`
    case 'cm':
      return css`
        background: linear-gradient(90deg, rgba(254, 222, 65, 0.54) 6.86%, rgba(246, 129, 25, 0) 90.76%);
        @media screen and (max-width: 425px) {
          background: linear-gradient(90deg, rgba(246, 129, 25, 0) 6.86%, rgba(254, 222, 65, 0.54) 90.76%);
        }
      `
  }
}

const setTransforomY = ({ pageName }) => {
  switch (pageName) {
    case 'mobile':
      return `transform: translateY(3%);`
    case 'booking':
      return `transform: translateY(3%);`
    case 'pms':
      return `transform: translateY(3%);`
    case 'cm':
      return `transform: translateY(-2%);`
  }
}

export const IntroContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60rem;
  background-color: white;
  ${setRadialGradientColor}
  position: relative;

  &::before {
    content: '';
    position: absolute;
    background: white;
    width: 100%;
    height: 100%;
    bottom: -2px;

    @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
      -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 10%, 0 97%);
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      backface-visibility: hidden;
      clip-path: polygon(0 100%, 100% 100%, 100% 75%, 0 97%);
    }
  }

  @media screen and (max-width: 1180px) {
    align-items: flex-start;
  }

  @media screen and (max-width: 1180px) {
    height: 100%;

    &::before {
      @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
        -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 83%, 0 83%);
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        backface-visibility: hidden;
        clip-path: polygon(0 100%, 100% 100%, 100% 83%, 0 83%);
      }
    }
  }
`

export const IntroBox = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 1180px) {
    flex-direction: column;
    padding-top: 12rem;
  }
`
export const Column = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1180px) {
    align-items: center;
  }
`

export const ImageContainer = styled.figure`
  width: 700px;
  height: 100%;
  transform: ${({ yAxis }) => `translate(-6%,${yAxis}%)`};
  opacity: 0;
  animation: fadeIn 0.3s forwards ease-in-out;

  @media screen and (max-width: 1286px) {
    width: 500px;
  }
  @media screen and (max-width: 1180px) {
    width: 620px;
    transform: translateY(9%);
  }

  @media screen and (max-width: 768px) {
    width: 500px;
    ${setTransforomY}
  }
  @media screen and (min-width: 500px) and (max-width: 599px) {
    width: 450px;
  }

  @media screen and (max-width: 499px) {
    max-width: 100%;
    width: 320px;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`
export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.lightestGrey};
  font-family: 'Barlow';
  font-size: 5rem;
  font-weight: 800;
  line-height: 6rem;
  width: 600px;
  @media screen and (min-width: 500px) and (max-width: 599px) {
    font-size: 3.5rem;
    text-align: center;
    line-height: 4rem;
    width: 80%;
  }
  @media screen and (min-width: 600px) and (max-width: 1180px) {
    font-size: 3.6rem;
    text-align: center;
    line-height: 4.2rem;
    width: 75%;
  }

  @media screen and (max-width: 499px) {
    font-size: 2rem;
    text-align: center;
    line-height: 2.5rem;
    width: 300px;
  }
`

export const Subtitle = styled.span`
  display: inline-flex;
  color: ${({ theme }) => theme.colors.lightestGrey};
  font-size: 1.14rem;
  line-height: 1;
  text-transform: uppercase;
  ${setLinearGradientColor}
  padding: 0.9rem 1rem;
  margin-bottom: 2rem;
  border-radius: 10px;
  width: 60%;

  @media screen and (max-width: 1180px) {
    display: block;
    width: auto;
    text-align: center;
    font-size: 1.2rem;
  }
`
