import React from 'react'

const AppleIcon = () => {
  return (
    <svg width="29" height="32" viewBox="0 0 29 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Apple Store</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.4448 17.1061C23.4055 13.1922 27.0137 11.288 27.1786 11.1994C25.1352 8.52406 21.968 8.15853 20.855 8.12939C18.1949 7.87801 15.6146 9.55872 14.2595 9.55872C12.8774 9.55872 10.7907 8.15367 8.54168 8.19496C5.64761 8.23504 2.94018 9.73966 1.45528 12.0761C-1.60919 16.8402 0.676313 23.8411 3.6123 27.6919C5.08097 29.5791 6.79713 31.6836 9.04341 31.6096C11.241 31.5294 12.0619 30.3515 14.7139 30.3515C17.3415 30.3515 18.1124 31.6096 20.4033 31.561C22.7618 31.5294 24.2467 29.6678 25.664 27.7636C27.3612 25.6044 28.0428 23.4732 28.0699 23.3639C28.0158 23.3481 23.4907 21.7973 23.4448 17.1061Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1174 5.59612C20.2994 4.26879 21.1081 2.463 20.8836 0.630493C19.1729 0.698499 17.0334 1.69308 15.8014 2.99126C14.7114 4.13521 13.7377 6.01022 13.9892 7.77351C15.911 7.90224 17.8841 6.9028 19.1174 5.59612Z"
        fill="white"
      />
    </svg>
  )
}

export default AppleIcon
